<script setup>
const backend = useBackend();
const route = useRoute();
const calculator = useCalculator();
const badRoutes = useBadRoutes();
const salesManago = useSalesManago();

const brand = computed(() => car ? car.value.data.brandName : "");
const model = computed(() => car ? car.value.data.modelName : "");
const version = computed(() => car ? car.value.data.versionName : "");

const {data: car, error} = await useAsyncData(() => new Promise(async (resolve, fail) => {
  const car = await backend.getCar(
    route.params.id
  );

  if (!car?.data || !car.data.count) {
    return fail([]);
  }

  await calculator.getInstallment(car);

  resolve(car);
}))

if (error.value) {
  // not works on dev, only on production!
  await badRoutes.handle()
}

useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {name: 'robots', content: 'noindex, follow'},
    {rel: 'canonical', href: 'https://vehis.pl'}
  ]
});

onMounted(() => salesManago.sendEvent('CART',car.value.data.group_id));
</script>


<template>
  <container>
  <LayoutColumns>
    <LayoutMainColumn noflex>
      <div class="flex p-6 rounded shadow-box gap-6 self-stretch flex-col md:flex-row">
        <div class="shrink">
          <FormContact :car="car.data" no-container fluid />
        </div>
        <div class="w-full md:w-[276px] w-min-[276px] shrink-0 font-extralight flex flex-col justify-center items-center
        border-t border-t-gray-400 md:border-t-0
        md:border-l md:border-l-gray-400 md:pl-6 pt-6 md:pt-0">
          <div class="text-xl">lub <span class="font-normal">kontynuuj zamówienie</span></div>
          <div class="text-xl text-celadon font-light">100% online</div>
          <div class="mt-4 w-full">
            <ButtonRounded color="celadon" :href="{name: 'transaction-build', params: { brandOrId: car.data.group_id}}">Składam wniosek online</ButtonRounded>
          </div>
        </div>
      </div>
    </LayoutMainColumn>
    <LayoutSideColumn class="!bg-transparent">
      <CarCard :car="car.data" no-button no-adjust offer-view />
    </LayoutSideColumn>
  </LayoutColumns>
  </container>
</template>
